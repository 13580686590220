<template>
  <v-row class="pt-3">
    <v-col cols="4" align-self="start" class="text-center py-1">
      {{ dataKey.name }}
    </v-col>
    <v-col cols="8" class="d-flex py-1">
      <div @click="editClick" v-if="!editValue">
        <v-hover v-slot="{ hover }" close-delay="100">
          <span
            class="subtitle-2 onSurface--text"
            :class="hover ? 'font-weight-medium' : 'font-weight-regular'"
            v-text="data[dataKey.value] != '' ? data[dataKey.value] : '-'"
          />
        </v-hover>
      </div>
      <div v-else>
        <v-textarea
          v-if="dataKey.value == 'printMemo'"
          v-click-outside="onClickOutside"
          v-model="saveData"
          label="Enter To Apply"
          class="surface rounded subtitle-2 font-weight-regular"
          rows="1"
          outlined
          background-color="white"
          flat
          dense
          hide-details
          no-resize
          auto-grow
          @keydown.enter="save()"
        />
        <v-text-field
          v-else
          v-click-outside="onClickOutside"
          dense
          outlined
          hide-details
          class="subtitle-2"
          label="Enter To Apply"
          v-model="saveData"
          @keydown.enter="save()"
          @keydown.esc="onClickOutside()"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: { data: Object, dataKey: Object },
  data() {
    return {
      editValue: false,
      saveData: "",
      // saveLoading: false,
    };
  },
  methods: {
    editClick() {
      this.saveData = this.data[this.dataKey.value];
      this.editValue = true;
    },
    onClickOutside() {
      this.editValue = false;
      this.saveData = this.data[this.dataKey.value];
    },
    async save() {
      this.editValue = false;
      this.data[this.dataKey.value] = this.saveData;
      //   this.editValue = false;
      //   if (this.saveLoading) {
      //     this.$toasted.global.error("이미 저장중인 내용이 있습니다.");
      //     return;
      //   }
      //   this.saveLoading = true;
      //   this.data[this.dataKey.value] = this.saveData;
      //   try {
      //     await this.$axios.put("admin/updateOrder/" + this.data.id, {
      //       admin: this.$store.getters["auth/user/GET_USER"].displayName,
      //       item: this.data,
      //     });
      //   } catch (e) {
      //     this.$toasted.global.error(e.message);
      //   }
      //   this.saveLoading = false;
    },
  },
};
</script>

<style></style>
