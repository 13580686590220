<template>
  <v-card class="px-4" flat>
    <v-card-actions class="px-4">
      <v-menu rounded bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            class="px-0 subtitle-2 font-weight-regular onSurface--text"
            min-width="10px"
            v-bind="attrs"
            v-on="on"
          >
            {{ fileView[fileViewOption] }} <v-icon> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(view, index) in fileView"
            :key="index"
            @click="fileViewOption = index"
          >
            <v-list-item-title>
              <span
                class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                v-text="view"
              />
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="fileViewOption < 3"
        class="ml-5 subtitle-2 font-weight-regular"
        text
        small
        @click="
          data.file.some((item) => !item.print)
            ? data.file.forEach((v) => (v.print = 1))
            : data.file.forEach((v) => (v.print = 0))
        "
      >
        전체선택
        <v-icon class="ml-1" size="18" color="primary"
          >{{
            data.file.some((item) => !item.print)
              ? "mdi-circle-outline"
              : "mdi-check-circle"
          }}
        </v-icon>
      </v-btn>
      <v-spacer />
      <div>
        <v-menu rounded="lg" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-1 mx-1"
              height="24px"
              min-width="10px"
              v-bind="attrs"
              v-on="on"
            >
              <span
                class="subtitle-2 font-weight-regular onSurface--text"
                v-text="getOption(changeAll).method.split('(')[0]"
              />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(printOption, index) in printOption"
              :key="index"
              @click="
                changeAll.method = index;
                changeAll.material = 0;
                changeAll.color = 0;
              "
            >
              <v-list-item-title>
                <span
                  class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                  v-text="printOption.method"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu rounded="lg" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-1 mx-1"
              height="24px"
              min-width="10px"
              v-bind="attrs"
              v-on="on"
            >
              <span
                class="subtitle-2 font-weight-regular onSurface--text"
                v-text="getOption(changeAll).material.split('(')[0]"
              />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(materialList, index) in printOption[changeAll.method]
                .material"
              :key="index"
              @click="
                changeAll.material = index;
                changeAll.color = 0;
              "
            >
              <v-list-item-title>
                <span
                  class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                  v-text="materialList.name"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu rounded="lg" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-1 mx-1"
              height="24px"
              min-width="10px"
              v-bind="attrs"
              v-on="on"
            >
              <span
                class="subtitle-2 font-weight-regular onSurface--text"
                v-text="getOption(changeAll).color.name.split('(')[0]"
              />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(colorList, index) in printOption[changeAll.method]
                .material[changeAll.material].color"
              :key="index"
              @click="changeAll.color = index"
            >
              <v-list-item-title>
                <v-icon
                  size="14"
                  :color="colorList.color"
                  class="mr-3"
                  style="border: 1px solid grey; border-radius: 50%"
                >
                  mdi-circle
                </v-icon>
                <span
                  class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                  v-text="colorList.name"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="px-1 mx-1 subtitle-2 primary"
          height="24px"
          @click="changeAllOption"
        >
          일괄 변경
        </v-btn>
      </div>
      <v-spacer />
      <v-btn
        height="24px"
        color="primary"
        @click="downloadAll(data.file, fileView[fileViewOption])"
      >
        Zip 다운로드
      </v-btn>
    </v-card-actions>
    <v-card-text
      :class="{ 'grey lighten-2': dragover }"
      @drop.prevent="onFileDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <v-row>
        <template v-for="(item, index) in data.file">
          <v-col
            cols="12"
            sm="6"
            md="4"
            xl="3"
            v-if="sortFile(item)"
            :key="index"
          >
            <v-card outlined>
              <v-card-actions>
                <v-btn icon x-small @click="setPrint(index)">
                  <v-icon class="mx-auto" size="18" color="primary">
                    {{ item.print ? "mdi-check-circle" : "mdi-circle-outline" }}
                  </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn icon x-small @click="copyFile(index, item)">
                  <feather size="18" type="copy" stroke="black" />
                </v-btn>
                <v-btn
                  icon
                  x-small
                  class="ml-3"
                  @click="deleteFile(item, index)"
                >
                  <feather
                    size="18"
                    type="trash"
                    :stroke="item.uploader == 'customer' ? 'grey' : 'red'"
                  />
                </v-btn>
              </v-card-actions>
              <v-card-text class="pt-1 px-2">
                <file-viewer :data="item" />
                <v-row>
                  <v-col cols="12">
                    <v-hover v-slot="{ hover }" close-delay="100">
                      <span
                        class="subtitle-2 font-weight-regular"
                        :class="
                          hover
                            ? 'font-weight-medium primary--text'
                            : 'font-weight-regular onSurface--text'
                        "
                        @click="hreflink(item.downloadURL)"
                        v-text="
                          item.filePath.split('/')[3]
                            ? item.filePath.split('/')[3]
                            : item.filePath.split('/')[2]
                        "
                      />
                    </v-hover>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="4" class="pt-0">
                    <span
                      class="subtitle-2 font-weight-regular"
                      v-text="'방식'"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex pt-0">
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                    >
                      <v-menu rounded="lg" bottom offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="px-0"
                            height="20px"
                            min-width="10px"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span
                              class="subtitle-2 font-weight-regular onSurface--text"
                              v-text="getOption(item).method.split('(')[0]"
                            />
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-for="(printOption, index) in printOption"
                            :key="index"
                            @click="
                              item.method = index;
                              item.material = 0;
                              item.color = 0;
                            "
                          >
                            <v-list-item-title>
                              <span
                                class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                                v-text="printOption.method"
                              />
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="4" class="pt-0">
                    <span
                      class="subtitle-2 font-weight-regular"
                      v-text="'소재'"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex pt-0">
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                    >
                      <v-menu rounded="lg" bottom offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="px-0"
                            height="20px"
                            min-width="10px"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span
                              class="subtitle-2 font-weight-regular onSurface--text"
                              v-text="getOption(item).material.split('(')[0]"
                            />
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-for="(materialList, index) in printOption[
                              item.method
                            ].material"
                            :key="index"
                            @click="
                              item.material = index;
                              item.color = 0;
                            "
                          >
                            <v-list-item-title>
                              <span
                                class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                                v-text="materialList.name"
                              />
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="4" class="pt-0">
                    <span
                      class="subtitle-2 font-weight-regular"
                      v-text="'색상'"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex pt-0">
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                    >
                      <v-menu rounded="lg" bottom offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="px-0"
                            height="20px"
                            min-width="10px"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span
                              class="subtitle-2 font-weight-regular onSurface--text"
                              v-text="getOption(item).color.name.split('(')[0]"
                            />
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-for="(colorList, index) in printOption[
                              item.method
                            ].material[item.material].color"
                            :key="index"
                            @click="item.color = index"
                          >
                            <v-list-item-title>
                              <v-icon
                                size="14"
                                :color="colorList.color"
                                class="mr-3"
                                style="
                                  border: 1px solid grey;
                                  border-radius: 50%;
                                "
                              >
                                mdi-circle
                              </v-icon>
                              <span
                                class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                                v-text="colorList.name"
                              />
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="4" class="pt-0">
                    <span
                      class="subtitle-2 font-weight-regular"
                      v-text="'수량'"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex pt-0">
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                    >
                      <v-menu
                        rounded="lg"
                        bottom
                        offset-y
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="px-0"
                            height="20px"
                            min-width="10px"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span
                              class="subtitle-2 font-weight-regular onSurface--text"
                              v-text="item.quantity.toLocaleString() + 'EA'"
                            />
                          </v-btn>
                        </template>
                        <v-card width="160px">
                          <v-card-text>
                            <v-text-field
                              :label="'작업 수량'"
                              v-model="item.quantity"
                              outlined
                              type="number"
                              hide-details
                              dense
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="4" class="pt-0">
                    <span
                      @click="showEachPrice = !showEachPrice"
                      class="subtitle-2 font-weight-regular"
                      v-text="showEachPrice ? '개당' : '합계'"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex pt-0">
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                    >
                      <v-menu
                        rounded="lg"
                        bottom
                        offset-y
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="px-0"
                            height="20px"
                            min-width="10px"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span
                              class="subtitle-2 font-weight-regular onSurface--text"
                              v-text="
                                showEachPrice
                                  ? localeString(item.price * 1) + '원'
                                  : localeString(item.price * item.quantity) +
                                    '원'
                              "
                            />
                          </v-btn>
                        </template>
                        <v-card width="160px">
                          <v-card-text>
                            <v-text-field
                              :label="'개당 금액'"
                              v-model="item.price"
                              outlined
                              type="number"
                              hide-details
                              dense
                            />
                            <div class="d-flex mt-2">
                              <span
                                class=""
                                v-text="
                                  '총 ' +
                                  localeString(item.price * item.quantity) +
                                  '원'
                                "
                              />
                            </div>
                            <div class="d-flex mt-3">
                              <span
                                class=""
                                v-text="
                                  '공급가 : ' +
                                  공급가(item.price * item.quantity) +
                                  '원'
                                "
                              />
                            </div>
                            <div class="d-flex mt-0">
                              <span
                                class=""
                                v-text="
                                  '부가세 : ' +
                                  부가세(item.price * item.quantity) +
                                  '원'
                                "
                              />
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="4" class="pt-0">
                    <span
                      class="subtitle-2 font-weight-regular"
                      v-text="'메모'"
                    />
                  </v-col>
                  <v-col cols="8" class="d-flex pt-0">
                    <span
                      class="subtitle-2 font-weight-regular onSurface--text"
                    >
                      <v-menu
                        rounded="lg"
                        bottom
                        offset-y
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="px-0"
                            height="20px"
                            min-width="10px"
                            v-bind="attrs"
                            v-on="on"
                            @click="openMemoDialog(item.memo, index)"
                          >
                            <span
                              class="subtitle-2 font-weight-regular onSurface--text"
                              v-text="
                                item.memo
                                  ? item.memo.length > 7
                                    ? item.memo.substring(0, 7) + '\u2026'
                                    : item.memo
                                  : '-'
                              "
                            />
                          </v-btn>
                        </template>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <v-dialog v-model="memoDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="title-1">
              <span class="title"> 메모 수정 </span>
              <v-spacer />
              <v-btn rounded icon color="onSurface" @click="closeMemoDialog()">
                <feather type="save" />
              </v-btn>
              <v-btn rounded icon color="onSurface" @click="memoDialog = false">
                <feather type="x-circle" />
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-textarea
                label="메모 내용"
                v-model="memoContext"
                outlined
                auto-grow
                rows="3"
                hide-details
                dense
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-card
            outlined
            @click="$refs.uploader.click()"
            height="100%"
            min-height="240px"
            class="upload-card"
          >
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileInput"
              multiple
            />
            <v-card-text class="d-flex text-center" style="height: 100%">
              <v-progress-circular
                v-if="uploadFileLoading"
                indeterminate
                color="primary"
                class="my-auto mx-auto"
              />
              <div v-else class="my-auto mx-auto">
                <v-icon size="40" class="d-flex" color="primary">
                  mdi-cloud-upload
                </v-icon>
                <span
                  class="subtitle-2 font-weight-bold primary--text"
                  v-text="'추가 파일 업로드'"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.upload-card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
<script>
import _ from "lodash";
import firestorageUtils from "@/utils/firestorage";
import constants from "@/utils/constants";
import JSZip from "jszip";
import FileSaver from "file-saver";
import FileViewer from "../../../Preview/fileViewer.vue";

export default {
  components: { FileViewer },
  props: { data: Object },

  data() {
    return {
      fileView: ["전체 파일", "원본 파일", "추가 파일", "출력 파일"],
      changeAll: { method: 0, material: 0, color: 0 },
      fileViewOption: 0,
      printOption: constants.printOption,
      showEachPrice: true,
      qtyMenu: false,
      dragover: false,
      uploadFileLoading: 0,
      memoDialog: false,
      memoContext: "",
      memoIndex: 0,
    };
  },
  watch: {
    data() {
      if (this.data.id) {
        this.data.status >= 4
          ? (this.fileViewOption = 3)
          : (this.fileViewOption = 0);
      } else {
        this.fileViewOption = 0;
      }
    },
  },
  methods: {
    openMemoDialog(item, index) {
      this.memoDialog = true;
      this.memoContext = item;
      this.memoIndex = index;
    },
    closeMemoDialog() {
      console.log(this.memoContext);
      this.data.file[this.memoIndex].memo = this.memoContext;
      this.memoDialog = false;
      console.log(this.data.file[this.memoIndex]);
    },
    sortFile(item) {
      if (this.fileViewOption == 0) return true;
      if (this.fileViewOption == 1) return item.uploader == "customer";
      if (this.fileViewOption == 2) return item.uploader != "customer";
      if (this.fileViewOption == 3) return item.print;
    },
    changeAllOption() {
      this.data.file.forEach((v) => {
        v.method = this.changeAll.method;
        v.material = this.changeAll.material;
        v.color = this.changeAll.color;
      });
    },
    downloadAll(file, index) {
      const zip = new JSZip();

      file.forEach((v) => {
        if (this.sortFile(v)) {
          const blobPromise = fetch(v.downloadURL).then((r) => {
            if (r.status === 200) return r.blob();
          });
          const name = v.filePath.substring(v.filePath.lastIndexOf("/") + 1);
          zip.folder(index).file(name, blobPromise);
        }
      });
      zip.generateAsync({ type: "blob" }).then((resZip) => {
        FileSaver(
          resZip,
          `${this.data.displayName}_${this.data.purpose}_${index}.zip`,
        );
      });
    },
    // 드래그 & 드랍 파일 인식
    onFileDrop(e) {
      this.uploadFileLoading = e.dataTransfer.files.length;
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
      e.dataTransfer.files = null;
    },
    // 일반 인풋 파일 인식
    onFileInput(event) {
      this.uploadFileLoading = event.target.files.length;
      event.target.files.forEach((v) => this.addFile(v));
      event.target.files = null;
    },
    // 파일 주문서에 추가 함수
    async addFile(v) {
      if (v.size > 100000000) {
        this.$toasted.global.error(`${v.name} 업로드 실패`);
        this.$toasted.global.error(`100MB 이하 파일만 등록 가능합니다.`);
      } else {
        const result = await firestorageUtils.uploadFile({
          file: v,
          path: `Orders/${this.data.id}/PrintFile/${v.name}`,
        });
        const fileInterface = {
          method: 0,
          material: 0,
          color: 0,
          quantity: 1,
          memo: "",
          previewImg: "",
          dimension: "",
          status: 0,
          uploader: this.$store.getters["auth/user/GET_USER"].displayName,
          filePath: result.filePath,
          downloadURL: result.downloadURL,
          print: false,
          price: 0,
        };
        this.data.file.push(fileInterface);
        this.uploadFileLoading--;
        this.saveData();
      }
    },
    setPrint(i) {
      this.data.file[i].print = !this.data.file[i].print;
    },
    copyFile(index, item) {
      const newData = _.cloneDeep(item);
      newData.uploader = this.$store.getters["auth/user/GET_USER"].displayName;
      this.data.file.splice(index + 1, 0, newData);
    },
    deleteFile(item, i) {
      if (item.uploader == "customer") {
        window.alert("고객이 업로드한 파일은 삭제할 수 없습니다!");
      } else {
        this.data.file.splice(i, 1);
        if (
          this.data.file.findIndex((v) => v.downloadURL == item.downloadURL) ==
          -1
        ) {
          try {
            if (
              item.previewImg &&
              (item.previewImg.startsWith("http://") ||
                item.previewImg.startsWith("https://"))
            ) {
              firestorageUtils.deleteFile(item.previewImg);
            }
            firestorageUtils.deleteFile(item.downloadURL);
          } catch (e) {
            this.$toasted.global.error(e.message);
          }
        }
        this.saveData();
      }
    },
    async saveData() {
      try {
        await this.$axios.put("admin/updateOrder/" + this.data.id, {
          admin: this.$store.getters["auth/user/GET_USER"].displayName,
          item: { file: this.data.file },
        });
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
    },
    getOption(i) {
      return {
        method: this.printOption[i.method].method,
        material: this.printOption[i.method].material[i.material].name,
        color: this.printOption[i.method].material[i.material].color[i.color],
      };
    },
    hreflink(link) {
      window.open(link);
    },
    localeString(i) {
      return i.toLocaleString();
    },
    공급가(i) {
      return Math.floor((i * 10) / 11).toLocaleString();
    },
    부가세(i) {
      return Math.floor(i / 11).toLocaleString();
    },
  },
};
</script>

<style></style>
