<template>
  <v-card outlined>
    <v-card-title>
      <span
        class="highlight subtitle-1 font-weight-bold onSurface--text"
        v-text="'접수 내용'"
      />
    </v-card-title>
    <v-card-text class="px-auto pb-8">
      <v-row>
        <v-col sm="6" cols="12">
          <v-row class="pt-3">
            <v-col cols="4" align-self="start" class="text-center py-1">
              접수 제목
            </v-col>
            <v-col cols="8" class="d-flex py-1">
              <span
                class="subtitle-2 font-weight-regular onSurface--text"
                v-text="data.purpose || '-'"
              />
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="4" align-self="start" class="text-center py-1">
              사용 환경
            </v-col>
            <v-col cols="8" class="d-flex py-1">
              <span
                class="subtitle-2 font-weight-regular onSurface--text"
                v-text="data.environment || '-'"
              />
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="4" align-self="start" class="text-center py-1">
              희망 납기
            </v-col>
            <v-col cols="8" class="d-flex py-1">
              <span class="subtitle-2 font-weight-regular onSurface--text">
                {{ data.dueAt || "-" | moment("YYYY/MM/DD") }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="6" cols="12">
          <v-row class="pt-3">
            <v-col cols="4" align-self="start" class="text-center py-1">
              진행 상황
            </v-col>
            <v-col cols="8" class="d-flex py-1">
              <span class="subtitle-2 font-weight-regular onSurface--text">
                <v-menu rounded="lg" bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="px-0"
                      height="20px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <feather
                        size="22"
                        :type="orderStatusItem[data.status].icon"
                        :stroke="
                          orderStatusItem[data.status].color
                            ? orderStatusItem[data.status].color
                            : 'var(--v-onSurface-base)'
                        "
                      />
                      <span
                        class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                        v-text="orderStatusItem[data.status].title"
                      />
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(statusItem, index) in orderStatusItem"
                      :key="index"
                      @click="data.status = index"
                    >
                      <v-list-item-title class="body-2">
                        <feather
                          size="18"
                          :type="statusItem.icon"
                          :stroke="statusItem.color"
                          class="mr-2"
                        />
                        {{ statusItem.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="4" align-self="start" class="text-center py-1">
              접수 일시
            </v-col>
            <v-col cols="8" class="d-flex py-1">
              <span class="subtitle-2 font-weight-regular onSurface--text">
                {{ data.createdAt | moment("YYYY/MM/DD HH:mm:ss") }}
              </span>
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col cols="4" align-self="start" class="text-center py-1">
              결제 일시
            </v-col>
            <v-col cols="8" class="d-flex py-1">
              <span class="subtitle-2 font-weight-regular onSurface--text">
                {{
                  data.confirmed
                    ? data.confirmedAt
                    : "-" | moment("YYYY/MM/DD HH:mm:ss")
                }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="2" align-self="start" class="text-center py-1">
          참고 사항
        </v-col>
        <v-col cols="10" class="d-flex py-1">
          <span
            class="subtitle-2 font-weight-regular onSurface--text"
            v-text="data.memo || '-'"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import constants from "@/utils/constants";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      orderStatusItem: constants.orderStatusItem,
    };
  },
  methods: {},
};
</script>

<style></style>
