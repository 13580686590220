<template>
  <v-card class="px-4" flat>
    <v-card-text> 후처리 내용을 입력하는 곳 입니다. </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: { data: Object },
};
</script>

<style></style>
