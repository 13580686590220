var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"highlight subtitle-1 font-weight-bold onSurface--text",domProps:{"textContent":_vm._s('작업 내용')}})]),_c('v-card-text',{staticClass:"px-auto pb-8"},[_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 총 결제액 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(_vm.data.productCost.toLocaleString() + '원' || '-')}})])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 정산 금액 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(_vm.data.workspaceCost.toLocaleString() + '원' || '-')}})])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 결제 방식 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text"},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"20px"}},'v-btn',attrs,false),on),[_c('feather',{attrs:{"size":"22","type":_vm.orderPayment[_vm.data.paymentMethod].icon,"stroke":_vm.orderPayment[_vm.data.paymentMethod].color
                      ? _vm.orderPayment[_vm.data.paymentMethod].color
                      : 'var(--v-onSurface-base)'}}),_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(_vm.orderPayment[_vm.data.paymentMethod].title)}})],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.orderPayment),function(Payment,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.data.paymentMethod = index}}},[_c('v-list-item-title',{staticClass:"body-2"},[_c('feather',{staticClass:"mr-2",attrs:{"size":"18","type":Payment.icon,"stroke":Payment.color}}),_vm._v(" "+_vm._s(Payment.title)+" ")],1)],1)}),1)],1)],1)])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 배송 방식 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text"},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"20px"}},'v-btn',attrs,false),on),[_c('feather',{attrs:{"size":"22","type":_vm.shippingMethod[_vm.data.shippingMethod].icon,"stroke":_vm.shippingMethod[_vm.data.shippingMethod].color
                      ? _vm.shippingMethod[_vm.data.shippingMethod].color
                      : 'var(--v-onSurface-base)'}}),_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(
                    _vm.shippingMethod[_vm.data.shippingMethod].title +
                    ' + ' +
                    _vm.shippingMethod[
                      _vm.data.shippingMethod
                    ].price.toLocaleString() +
                    '원'
                  )}})],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.shippingMethod),function(Shipping,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.data.shippingMethod = index}}},[_c('v-list-item-title',{staticClass:"body-2"},[_c('feather',{staticClass:"mr-2",attrs:{"size":"18","type":Shipping.icon,"stroke":Shipping.color}}),_vm._v(" "+_vm._s(Shipping.title + " | " + Shipping.price.toLocaleString() + "원")+" ")],1)],1)}),1)],1)],1)])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 발송 기한 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('v-menu',{staticClass:"rounded-lg",attrs:{"close-on-content-click":false,"nudge-left":100,"transition":"scale-transition ","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"20px","min-width":"0px"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(_vm.data.shipAt || '-')}})])]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-date-picker',{attrs:{"show-current":"","locale":"ko","flat":"","color":"primary"},on:{"input":function($event){_vm.datepicker = false}},model:{value:(_vm.data.shipAt),callback:function ($$v) {_vm.$set(_vm.data, "shipAt", $$v)},expression:"data.shipAt"}})],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 작업 장소 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text"},[_c('v-menu',{attrs:{"rounded":"lg","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"text":"","height":"20px","min-width":"10px"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(_vm.data.workspace)}})])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.makerSpace),function(workSpace,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.data.workspace = workSpace}}},[_c('v-list-item-title',[_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s(workSpace)}})])],1)}),1)],1)],1)])],1),_c('admin-order-dialog-edit',{attrs:{"data":_vm.data,"dataKey":{ name: '특이 사항', value: 'printMemo' }}}),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 견 적 서 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[(_vm.data.quoteFilePath)?_c('div',[_c('v-btn',{staticClass:"px-0",attrs:{"href":_vm.data.quoteDownloadUrl,"target":"_blank","height":"20px","text":""}},[_c('feather',{attrs:{"size":"22","type":"download","stroke":'var(--v-onSurface-base)',"stroke-width":"1.5px"}}),_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s('다운로드')}})],1),_c('v-btn',{staticClass:"ml-5 px-0",attrs:{"height":"20px","min-width":"0px","text":""},on:{"click":function($event){return _vm.deleteQuote()}}},[_c('feather',{attrs:{"size":"22","type":"trash","stroke":"red","stroke-width":"1.5px"}}),_c('span',{staticClass:"ml-2 subtitle-2 font-weight-regular onSurface--text",domProps:{"textContent":_vm._s('삭제')}})],1)],1):_c('div',{on:{"click":function($event){_vm.dialog = true;
            _vm.docType = 'quote';}}},[_c('span',{staticClass:"subtitle-2 onSurface--text font-weight-regular",domProps:{"textContent":_vm._s('미리보기')}})])])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 주 문 서 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('div',{on:{"click":function($event){_vm.dialog = true;
            _vm.docType = 'printList';}}},[_c('span',{staticClass:"subtitle-2 onSurface--text font-weight-regular",domProps:{"textContent":_vm._s('미리보기')}})])])],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-center py-1",attrs:{"cols":"4","align-self":"start"}},[_vm._v(" 거래명세표 ")]),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"8"}},[_c('div',{on:{"click":function($event){_vm.dialog = true;
            _vm.docType = 'receipt';}}},[_c('span',{staticClass:"subtitle-2 onSurface--text font-weight-regular",domProps:{"textContent":_vm._s('미리보기')}})])])],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('order-docs-dialog',{attrs:{"dialog":_vm.dialog,"type":_vm.docType,"data":_vm.data},on:{"closeDialog":_vm.closeDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }