<template>
  <v-dialog
    v-model="dialog"
    persistent
    @keydown.esc="$emit('closeDialog')"
    @click:outside="$emit('closeDialog')"
  >
    <v-card rounded="lg">
      <v-card-actions class="py-4">
        <!-- 삭제버튼 -->
        <v-btn
          color="error"
          class="rounded-lg font-weight-bold"
          depressed
          @click="dialogDelete = true"
          icon
        >
          <feather type="trash" />
        </v-btn>
        <!-- 삭제 누르면 뜨는 Dialog -->
        <v-dialog v-model="dialogDelete" max-width="280px">
          <v-card class="rounded-xl text-center pa-2" flat>
            <v-card-title class="title font-weight-bold">
              선택한 항목을 삭제하겠습니까?
            </v-card-title>

            <v-card-subtitle class="ma-0">
              해당 항목이 즉시 삭제됩니다. <br />
              이 동작은 취소할 수 없습니다.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="surface rounded-lg"
                depressed
                @click="dialogDelete = false"
              >
                취소
              </v-btn>
              <v-btn
                color="error rounded-lg"
                depressed
                @click="deleteItemConfirm"
              >
                삭제
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <v-btn
          rounded
          icon
          :disabled="!saveBtn"
          :color="saveBtn ? 'onSurface' : 'grey'"
          @click="$emit('saveOrder', data)"
        >
          <feather type="save" />
        </v-btn>
        <v-btn rounded icon color="onSurface" @click="$emit('closeDialog')">
          <feather type="x-circle" />
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-row>
          <v-col lg="3" md="4" cols="12">
            <v-row>
              <v-col md="12" sm="6" cols="12">
                <user-profile :data="data" />
              </v-col>
              <v-col md="12" sm="6" cols="12">
                <chat-msg-card :data="data" />
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="9" md="8" cols="12">
            <v-row>
              <v-col lg="8" cols="12">
                <v-row>
                  <v-col cols="12">
                    <order-info-card :data="data" />
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-card-title @click="modelingCard = !modelingCard">
                        <span
                          class="highlight subtitle-1 font-weight-bold onSurface--text"
                          v-text="'3D모델링'"
                        />
                        <v-spacer />
                        <v-icon>
                          {{
                            modelingCard ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-card-title>
                      <v-expand-transition>
                        <modeling-card v-if="modelingCard" :data="data" />
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-card-title @click="printCard = !printCard">
                        <span
                          class="highlight subtitle-1 font-weight-bold onSurface--text"
                          v-text="'3D프린팅'"
                        />
                        <v-spacer />
                        <span
                          class="mr-2"
                          v-text="data.id ? printTotalPrice() : ''"
                        />
                        <v-icon>
                          {{
                            printCard ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-card-title>
                      <v-expand-transition>
                        <print-file-card v-if="printCard" :data="data" />
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-card-title @click="postCard = !postCard">
                        <span
                          class="highlight subtitle-1 font-weight-bold onSurface--text"
                          v-text="'후처리'"
                        />
                        <v-spacer />
                        <v-icon>
                          {{ postCard ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>
                      </v-card-title>
                      <v-expand-transition>
                        <post-process-card v-if="postCard" :data="data" />
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="4" cols="12">
                <v-row>
                  <v-col cols="12">
                    <work-info-card :data="data" />
                  </v-col>
                  <v-col cols="12">
                    <status-change-card :data="data" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import workInfoCard from "../../../Card/Admin/Order/workInfoCard.vue";
import orderInfoCard from "../../../Card/Admin/Order/orderInfoCard.vue";
import ChatMsgCard from "../../../Card/Admin/Order/chatMsgCard.vue";
import userProfile from "../../../Card/Admin/Order/userProfile.vue";
import PrintFileCard from "../../../Card/Admin/Order/printFileCard.vue";
import ModelingCard from "../../../Card/Admin/Order/modelingCard.vue";
import PostProcessCard from "../../../Card/Admin/Order/postProcessCard.vue";
import StatusChangeCard from "../../../Card/Admin/Order/statusChangeCard.vue";
import constants from "@/utils/constants";
import _ from "lodash";
export default {
  props: {
    dialog: Boolean,
    data: Object,
  },
  components: {
    workInfoCard,
    orderInfoCard,
    userProfile,
    ChatMsgCard,
    PrintFileCard,
    ModelingCard,
    PostProcessCard,
    StatusChangeCard,
  },
  data() {
    return {
      dialogDelete: false,
      modelingCard: false,
      printCard: true,
      postCard: false,
      shippingMethod: constants.shippingMethod,
      saveBtn: false,
      saveData: {},
    };
  },
  watch: {
    dialog() {
      this.saveData = _.cloneDeep(this.data);
    },
    data: {
      handler() {
        _.isEqual(this.data, this.saveData)
          ? (this.saveBtn = false)
          : (this.saveBtn = true);
      },
      deep: true,
    },
  },
  methods: {
    async deleteItemConfirm() {
      try {
        await this.$axios.patch("admin/deleteOrder/" + this.data.id, {
          item: this.data,
        });
      } catch (e) {
        this.$toasted.global.error(e);
      }
      this.dialogDelete = false;
      this.$emit("closeDialog");
    },
    printTotalPrice() {
      let totalPrice = 0;
      this.data.file &&
        this.data.file.forEach((v) => {
          if (v.price && v.print) totalPrice += v.price * v.quantity;
        });
      this.data.productCost =
        totalPrice + this.shippingMethod[this.data.shippingMethod].price;
      this.data.workspaceCost = this.data.productCost;
      return totalPrice.toLocaleString() + "원";
    },
  },
};
</script>

<style></style>
