<template>
  <v-card outlined>
    <v-card-title>
      <span
        class="highlight subtitle-1 font-weight-bold onSurface--text"
        v-text="'진행 내역'"
      />
    </v-card-title>
    <v-card-text class="px-auto pb-8">
      <v-hover
        v-slot="{ hover }"
        close-delay="50"
        v-for="item in data.statusChanged.slice().reverse()"
        :key="item.date"
      >
        <v-card tile flat :class="hover ? 'grey  lighten-3' : ''">
          <v-card-text class="py-1">
            <div class="mr-auto onSurface--text">
              <span>
                {{ item.date | moment("MM/DD HH:mm:ss") }}
              </span>
              <span class="pl-2" v-text="item.charge || '시스템'" />
            </div>
            <div class="mr-auto">
              <span class="pl-2" v-text="'주문 상태'" />
              <span class="pl-3" v-text="orderStatusItem[item.status].title" />
            </div>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-card-text>
  </v-card>
</template>

<script>
import constants from "@/utils/constants";
export default {
  props: {
    data: Object,
  },
  data() {
    return { orderStatusItem: constants.orderStatusItem };
  },
};
</script>

<style></style>
