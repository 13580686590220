<template>
  <v-card outlined>
    <v-card-title>
      <span
        class="highlight subtitle-1 font-weight-bold onSurface--text"
        v-text="'작업 내용'"
      />
    </v-card-title>
    <v-card-text class="px-auto pb-8">
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          총 결제액
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span
            class="subtitle-2 font-weight-regular onSurface--text"
            v-text="data.productCost.toLocaleString() + '원' || '-'"
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          정산 금액
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span
            class="subtitle-2 font-weight-regular onSurface--text"
            v-text="data.workspaceCost.toLocaleString() + '원' || '-'"
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          결제 방식
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span class="subtitle-2 font-weight-regular onSurface--text">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="px-0" height="20px" v-bind="attrs" v-on="on">
                  <feather
                    size="22"
                    :type="orderPayment[data.paymentMethod].icon"
                    :stroke="
                      orderPayment[data.paymentMethod].color
                        ? orderPayment[data.paymentMethod].color
                        : 'var(--v-onSurface-base)'
                    "
                  />
                  <span
                    class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                    v-text="orderPayment[data.paymentMethod].title"
                  />
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="(Payment, index) in orderPayment"
                  :key="index"
                  @click="data.paymentMethod = index"
                >
                  <v-list-item-title class="body-2">
                    <feather
                      size="18"
                      :type="Payment.icon"
                      :stroke="Payment.color"
                      class="mr-2"
                    />
                    {{ Payment.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          배송 방식
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span class="subtitle-2 font-weight-regular onSurface--text">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text class="px-0" height="20px" v-bind="attrs" v-on="on">
                  <feather
                    size="22"
                    :type="shippingMethod[data.shippingMethod].icon"
                    :stroke="
                      shippingMethod[data.shippingMethod].color
                        ? shippingMethod[data.shippingMethod].color
                        : 'var(--v-onSurface-base)'
                    "
                  />
                  <span
                    class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                    v-text="
                      shippingMethod[data.shippingMethod].title +
                      ' + ' +
                      shippingMethod[
                        data.shippingMethod
                      ].price.toLocaleString() +
                      '원'
                    "
                  />
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="(Shipping, index) in shippingMethod"
                  :key="index"
                  @click="data.shippingMethod = index"
                >
                  <v-list-item-title class="body-2">
                    <feather
                      size="18"
                      :type="Shipping.icon"
                      :stroke="Shipping.color"
                      class="mr-2"
                    />
                    {{
                      Shipping.title +
                      " | " +
                      Shipping.price.toLocaleString() +
                      "원"
                    }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          발송 기한
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
            :nudge-left="100"
            transition="scale-transition "
            class="rounded-lg"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                class="px-0"
                height="20px"
                min-width="0px"
                v-bind="attrs"
                v-on="on"
              >
                <span
                  class="subtitle-2 font-weight-regular onSurface--text"
                  v-text="data.shipAt || '-'"
                />
              </v-btn>
            </template>
            <v-date-picker
              v-model="data.shipAt"
              @input="datepicker = false"
              show-current
              locale="ko"
              flat
              color="primary"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          작업 장소
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span class="subtitle-2 font-weight-regular onSurface--text">
            <v-menu rounded="lg" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="px-0"
                  height="20px"
                  min-width="10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span
                    class="subtitle-2 font-weight-regular onSurface--text"
                    v-text="data.workspace"
                  />
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(workSpace, index) in makerSpace"
                  :key="index"
                  @click="data.workspace = workSpace"
                >
                  <v-list-item-title>
                    <span
                      class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                      v-text="workSpace"
                    />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
      </v-row>
      <admin-order-dialog-edit
        :data="data"
        :dataKey="{ name: '특이 사항', value: 'printMemo' }"
      />
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          견 적 서
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <div v-if="data.quoteFilePath">
            <v-btn
              :href="data.quoteDownloadUrl"
              target="_blank"
              class="px-0"
              height="20px"
              text
            >
              <feather
                size="22"
                type="download"
                :stroke="'var(--v-onSurface-base)'"
                stroke-width="1.5px"
              />
              <span
                class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                v-text="'다운로드'"
              />
            </v-btn>
            <v-btn
              height="20px"
              min-width="0px"
              class="ml-5 px-0"
              @click="deleteQuote()"
              text
            >
              <feather
                size="22"
                type="trash"
                stroke="red"
                stroke-width="1.5px"
              />
              <span
                class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                v-text="'삭제'"
              />
            </v-btn>
          </div>
          <div
            v-else
            @click="
              dialog = true;
              docType = 'quote';
            "
          >
            <span
              class="subtitle-2 onSurface--text font-weight-regular"
              v-text="'미리보기'"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          주 문 서
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <div
            @click="
              dialog = true;
              docType = 'printList';
            "
          >
            <span
              class="subtitle-2 onSurface--text font-weight-regular"
              v-text="'미리보기'"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="start" class="text-center py-1">
          거래명세표
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <div
            @click="
              dialog = true;
              docType = 'receipt';
            "
          >
            <span
              class="subtitle-2 onSurface--text font-weight-regular"
              v-text="'미리보기'"
            />
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="800px">
        <order-docs-dialog
          :dialog="dialog"
          :type="docType"
          :data="data"
          @closeDialog="closeDialog"
        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import firestorageUtils from "@/utils/firestorage";
import AdminOrderDialogEdit from "../../../Dialog/Admin/Order/AdminOrderDialogEdit.vue";
import OrderDocsDialog from "../../../Dialog/OrderDocsDialog.vue";
import constants from "@/utils/constants";
export default {
  props: { data: Object },
  components: { AdminOrderDialogEdit, OrderDocsDialog },
  data() {
    return {
      orderPayment: constants.orderPayment,
      shippingMethod: constants.shippingMethod,
      makerSpace: constants.makerSpace,
      datepicker: false,
      btnLoading: false,
      dialog: false,
      docType: "",
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async deleteQuote() {
      try {
        firestorageUtils.deleteFile(this.data.quoteDownloadUrl);
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
      this.data.quoteFilePath = "";
      this.data.quoteDownloadUrl = "";
      this.saveData();
    },
    quoteUploadBtn() {
      this.btnLoading = true;
      window.addEventListener(
        "focus",
        () => {
          this.btnLoading = false;
        },
        { once: true },
      );

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      const quoteFile = e.target.files[0];
      this.$toasted.global.notice(
        "견적서 업로드 중입니다. 잠시만 기다려주세요!",
      );
      try {
        const result = await firestorageUtils.uploadFile({
          file: quoteFile,
          path: `Orders/${this.data.id}/${quoteFile.name}`,
        });
        this.data.quoteFilePath = result.filePath;
        this.data.quoteDownloadUrl = result.downloadURL;
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
      this.saveData();
    },
    async saveData() {
      try {
        await this.$axios.put("admin/updateOrder/" + this.data.id, {
          admin: this.$store.getters["auth/user/GET_USER"].displayName,
          item: {
            quoteFilePath: this.data.quoteFilePath,
            quoteDownloadUrl: this.data.quoteDownloadUrl,
          },
        });
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
    },
  },
};
</script>

<style></style>
