<template>
  <v-card outlined>
    <v-card-title>
      <span
        class="highlight subtitle-1 font-weight-bold onSurface--text"
        v-text="'채널톡'"
      />
      <v-spacer />
      <v-btn
        v-if="data.channelTalk && data.channelTalk.chat"
        small
        text
        class="px-0 subtitle-2"
        :href="`https://desk.channel.io/madeall3d/user-chats/${data.channelTalk.chat.name}-${data.channelTalk.chat.id}`"
        target="_blank"
      >
        바로가기
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="">
      <v-card color="#9bbbd4" v-if="data.channelTalk">
        <v-card-text
          style="overflow-y: scroll; height: 540px"
          ref="chatMsgCard"
        >
          <v-card
            v-for="item in chatMsg"
            :key="item.createdAt"
            class="mb-2"
            :class="item.personType == 'user' ? 'mr-3' : 'ml-3 text-end'"
          >
            <v-card-text>
              <span> {{ item.plainText }} </span>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions
      class="px-4 mb-3 d-flex"
      style="flex-direction: column; gap: 8px"
    >
      <v-textarea
        outlined
        v-model="message"
        label="고객에게 메세지를 보내보세요"
        rows="3"
        hide-details
        single-line
        style="width: 100%"
        @keydown="handleKeydown"
      />
      <div class="d-flex justify-between-space" style="width: 100%">
        <v-btn @click="sendQuote()" class="px-1" text>#금액 안내</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="sendMsg()"> 전송 </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return { chatMsg: [], message: "" };
  },
  watch: {
    data() {
      if (this.data.id) this.getChannelMsg();
    },
  },
  mounted() {
    this.getChannelMsg();
  },
  methods: {
    async getChannelMsg() {
      this.chatMsg = [];
      if (
        this.data.channelTalk &&
        this.data.channelTalk.chat &&
        this.data.channelTalk.chat.id
      ) {
        const t = await this.$axios.patch(
          "channelTalk/getMsg/" + this.data.channelTalk.chat.id
        );
        this.chatMsg = t.data;
        this.$nextTick(() => {
          this.$refs.chatMsgCard.scrollTop =
            this.$refs.chatMsgCard.scrollHeight;
        });
      }
    },
    async sendQuote() {
      if (this.data.file.findIndex((v) => v.print == true) == -1)
        return this.$toasted.global.error("선택된 파일이 없습니다!");
      await this.$axios.post("notice/sendQuote/", this.data);
      this.getChannelMsg();
    },

    handleKeydown(e) {
      if (e.key === "Enter" && !e.shiftKey) {
        // Enter만 눌렸을 때
        e.preventDefault(); // 기본 이벤트(줄바꿈) 방지
        this.sendMsg();
      } else if (e.key === "Enter" && e.shiftKey) {
        // Shift+Enter가 눌렸을 때
        // 기본적으로 줄바꿈이 수행됩니다.
      }
    },
    async sendMsg() {
      await this.$axios.post(
        "notice/sendMsg/" + this.data.channelTalk.chat.id,
        { message: this.message }
      );
      this.message = "";
      this.getChannelMsg();
    },
  },
};
</script>

<style></style>
