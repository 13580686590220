<template>
  <v-card rounded="lg">
    <v-card-title class="headline">
      {{ typeToString() }} 미리보기
      <v-spacer />
      <v-btn rounded icon color="onSurface" class="mr-4" @click="sendOrderData">
        <feather type="upload" />
      </v-btn>
      <v-btn rounded icon color="onSurface" class="mr-4" @click="printPDF">
        <feather type="printer" />
      </v-btn>
      <v-btn rounded icon color="onSurface" class="mr-4" @click="downloadPDF">
        <feather type="download" />
      </v-btn>
      <v-btn rounded icon color="onSurface" @click="$emit('closeDialog')">
        <feather type="x-circle" />
      </v-btn>
    </v-card-title>
    <v-card-text
      class="pt-5"
      style="overflow-x: scroll; display: flex; justify-content: center"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 600px;
        "
      >
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          size="64"
          style="margin: auto"
        />
        <iframe
          ref="pdf"
          v-if="!loading && pdfPreviewUrl"
          :src="`${pdfPreviewUrl}#toolbar=0`"
          width="100%"
          height="100%"
          frameborder="0"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    pdfPreviewUrl: "",
    pdfName: "",
    loading: false,
  }),
  props: {
    dialog: Boolean,
    data: Object,
    type: String,
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.loadFile();
        } else {
          this.pdfPreviewUrl = "";
          this.pdfName = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    typeToString() {
      switch (this.type) {
        case "quote":
          return "견적서";
        case "printList":
          return "주문서";
        case "receipt":
          return "거래명세표";
        default:
          return "/오류/";
      }
    },
    loadFile() {
      if (
        this.type == "printList" &&
        this.data.docs.orderDocPath !== undefined &&
        this.data.docs.orderDocPath != ""
      ) {
        this.pdfPreviewUrl = this.data.docs.orderDocDownloadUrl;
        this.pdfName = this.data.docs.orderDocPath.split("/")[3];
      } else if (
        this.type == "quote" &&
        this.data.docs.quoteFilePath !== undefined &&
        this.data.docs.quoteFilePath != ""
      ) {
        this.pdfPreviewUrl = this.data.docs.quoteDownloadUrl;
        this.pdfName = this.data.docs.quoteFilePath.split("/")[3];
      } else if (
        this.type == "receipt" &&
        this.data.docs.receiptFilePath !== undefined &&
        this.data.docs.receiptFilePath != ""
      ) {
        this.pdfPreviewUrl = this.data.docs.receiptDownloadUrl;
        this.pdfName = this.data.docs.receiptFilePath.split("/")[3];
      } else {
        this.sendOrderData();
      }
    },
    async sendOrderData() {
      this.loading = true;
      this.pdfPreviewUrl = "";

      // Filter files where print is true
      const filteredFiles = this.data.file.filter((file) => file.print == true);

      // 파일 수가 10개 이상일 경우 사용자에게 알림 및 함수 실행 중단
      if (this.type == "printList" && filteredFiles.length > 10) {
        this.$toasted.global.error("파일 수가 10개를 초과할 수 없습니다."); // 사용자에게 오류 메시지 표시
        this.loading = false; // 로딩 상태 업데이트
        return; // 함수 실행 중단
      }

      // Replace the existing file array with the filtered one
      const dataToSend = {
        ...this.data,
        file: filteredFiles,
      };

      try {
        const response = await this.$axios.patch(
          `admin/getDocs/${this.type}`,
          dataToSend, // Send the modified data with filtered files
        );
        console.log(response);
        this.pdfPreviewUrl = response.data.url;
        this.pdfName = response.data.name;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false; // Ensure loading is set to false in both success and error cases
      }
    },
    async downloadPDF() {
      const link = document.createElement("a");
      const response = await fetch(this.pdfPreviewUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      link.href = blobUrl;
      link.download = this.pdfName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async printPDF() {
      try {
        const response = await fetch(this.pdfPreviewUrl);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        const iframe = this.$refs.pdf;
        iframe.src = objectUrl + "#toolbar=0";
        iframe.onload = () => {
          iframe.focus();
          iframe.contentWindow.print();
        };
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
