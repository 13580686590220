<template>
  <v-card outlined>
    <v-card-title>
      <span
        class="highlight subtitle-1 font-weight-bold onSurface--text"
        v-text="'주문자 정보'"
      />
      <v-spacer />
      <v-btn
        small
        text
        class="px-0 subtitle-2"
        v-if="data.uid"
        @click="$router.push({ path: 'users/' + data.uid })"
      >
        고객 프로필
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-auto pb-8">
      <v-row class="pt-3">
        <v-col cols="4" align-self="center" class="text-center py-1">
          성함
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span
            class="subtitle-2 font-weight-regular onSurface--text"
            v-text="data.displayName"
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="center" class="text-center py-1">
          연락처
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span
            class="subtitle-2 font-weight-regular onSurface--text"
            v-text="
              data.phoneNumber &&
              data.phoneNumber
                .toString()
                .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
            "
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col cols="4" align-self="center" class="text-center py-1">
          구분
        </v-col>
        <v-col cols="8" class="d-flex py-1">
          <span
            class="subtitle-2 font-weight-regular onSurface--text"
            v-text="data.uid ? userClass[data.userClass].text : '비회원'"
          />
        </v-col>
      </v-row>
      <div v-if="data.userClass == 1" class="pt-3 mt-3">
        <v-row class="pt-3">
          <v-col cols="4" align-self="center" class="text-center py-1">
            학교
          </v-col>
          <v-col cols="8" class="d-flex py-1">
            <span
              class="subtitle-2 font-weight-regular onSurface--text"
              v-text="data.schoolInfo.school"
            />
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col cols="4" align-self="center" class="text-center py-1">
            학번
          </v-col>
          <v-col cols="8" class="d-flex py-1">
            <span
              class="subtitle-2 font-weight-regular onSurface--text"
              v-text="data.schoolInfo.studentId"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="data.userClass == 2" class="pt-3 mt-3">
        <v-row class="pt-3">
          <v-col cols="4" align-self="center" class="text-center py-1">
            기업명
          </v-col>
          <v-col cols="8" class="d-flex py-1">
            <span
              class="subtitle-2 font-weight-regular onSurface--text"
              v-text="data.companyInfo.companyName"
            />
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col cols="4" align-self="center" class="text-center py-1">
            업태
          </v-col>
          <v-col cols="8" class="d-flex py-1">
            <span
              class="subtitle-2 font-weight-regular onSurface--text"
              v-text="data.companyInfo.productClass"
            />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import constants from "@/utils/constants";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      userClass: constants.userClass,
    };
  },
};
</script>

<style></style>
