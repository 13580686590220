<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            <span class=""> 일정 관리 </span>
          </v-card-title>
          <v-card-subtitle> 한 눈에 확인 가능한 작업 일정표 </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="4" align-self="center"> </v-col>
      <v-col cols="12">
        <v-card class="pa-4 rounded-lg" outlined>
          <v-card-title class="subtitle-1 font-weight-bold">
            작업 목록
            <v-spacer />

            <v-autocomplete
              v-model="makerspaceSearchModel"
              :items="makerSpace"
              :search-input.sync="makerspaceSearch"
              cache-items
              hide-no-data
              hide-details
              style="max-width: 320px"
              clearable
              class="font-weight-regular"
              label="작업장을 선택하세요."
            />
            <!-- <v-btn
              class="mr-2"
              icon
              @click="confirmedList"
              :disabled="confirmedFetchLoading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-autocomplete
              v-model="confirmedOrderSearchModel"
              :items="confirmedOrderSearchItems"
              :search-input.sync="confirmedOrderSearch"
              cache-items
              class="ml-4 font-weight-regular"
              flat
              dense
              append-icon=""
              hide-no-data
              hide-details
              solo
              background-color="surface"
              prepend-inner-icon="mdi-magnify"
              label="성함을 입력하세요"
              clearable
            /> -->
          </v-card-title>
          <v-data-table
            :loading="confirmedFetchLoading"
            :headers="confirmedHeaders"
            :items="confirmedOrder"
            :items-per-page="confirmedOrder.length"
            hide-default-footer
            class="transparent"
            no-data-text="데이터가 없습니다"
            @click:row="openDialog"
          >
            <template v-slot:[`item.displayName`]="{ item }">
              <span>
                {{
                  item.displayName != ""
                    ? item.displayName
                    : item.phoneNumber.substr(item.phoneNumber.length - 4, 4)
                }}
              </span>
            </template>

            <template v-slot:[`item.confirmedAt`]="{ item }">
              <span>
                {{ item.confirmedAt | moment("MM/DD &nbsp;HH:mm") }}
              </span>
            </template>

            <template v-slot:[`item.shipAt`]="{ item }">
              <span>
                {{ item.shipAt || "-" | moment("MM/DD") }}
              </span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-menu rounded="lg" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <feather
                      size="24"
                      :type="getStatusData(item.status).icon"
                      :stroke="
                        getStatusData(item.status).color
                          ? getStatusData(item.status).color
                          : 'var(--v-onSurface-base)'
                      "
                    />
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(statusItem, index) in orderStatusItem"
                    :key="index"
                    @click="changeStatusData(item, index)"
                  >
                    <v-list-item-title class="body-2">
                      <feather
                        size="18"
                        :type="statusItem.icon"
                        :stroke="statusItem.color"
                        class="mr-2"
                      />
                      {{ statusItem.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.productCost`]="{ item }">
              <span>{{ item.productCost.toLocaleString() }}원</span>
            </template>

            <template v-slot:[`item.docs`]="{ item }">
              <v-btn
                icon
                v-if="!item.docs.print && item.docs.orderDocPath"
                @click.stop="printPDF(item)"
              >
                <feather size="24" type="printer" />
              </v-btn>
            </template>

            <template v-slot:[`item.paymentMethod`]="{ item }">
              <feather
                size="22"
                :type="orderPayment[item.paymentMethod].icon"
                :stroke="
                  orderPayment[item.paymentMethod].color
                    ? orderPayment[item.paymentMethod].color
                    : 'var(--v-onSurface-base)'
                "
              />
            </template>
            <template v-slot:[`item.shippingMethod`]="{ item }">
              <span v-text="shippingMethod[item.shippingMethod].title" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <admin-order-dialog
      :dialog="orderDialog"
      @saveOrder="saveOrder"
      :data="dialogData"
      @closeDialog="closeDialog"
    />
    <iframe ref="pdf" src="" frameborder="0" style="display: none" />
  </v-container>
</template>

<script>
import _ from "lodash";
import firestorageUtils from "@/utils/firestorage";
import setMeta from "@/utils/setMeta";
import constants from "@/utils/constants";
import AdminOrderDialog from "../../components/Dialog/Admin/Order/AdminOrderDialog.vue";

export default {
  components: {
    AdminOrderDialog,
  },
  data() {
    return {
      orderStatusItem: constants.orderStatusItem,
      makerSpace: constants.makerSpace,
      shippingMethod: constants.shippingMethod,
      orderPayment: constants.orderPayment,

      confirmedHeaders: [
        {
          text: "성함",
          align: "start",
          sortable: false,
          value: "displayName",
          width: "10%",
        },
        { text: "상태", value: "status", width: "8%" },
        { text: "견적 제목", value: "purpose", width: "35%" },
        { text: "요청 기한", value: "shipAt", width: "10%" },
        { text: "결제 일시", value: "confirmedAt", width: "15%" },
        { text: "결제", value: "paymentMethod", width: "8%" },
        { text: "배송 방법", value: "shippingMethod", width: "10%" },
        {
          text: "결제 금액",
          value: "productCost",
          width: "27%",
          align: "right",
        },
        {
          text: "주문서",
          value: "docs",
          width: "90px",
        },
      ],
      confirmedFetchLoading: true,
      confirmedOrder: [],

      makerspaceSearchModel: "메이드올 대구지사",
      makerspaceSearch: null,

      orderDialog: false,
      dialogData: {},

      unsubscribe: null,
    };
  },
  beforeCreate() {
    setMeta({
      title: "일정 관리",
      description: "메이드올 관리자페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  mounted() {
    this.confirmedList();
  },
  watch: {
    makerspaceSearchModel(n, o) {
      if (n !== o) this.confirmedList();
    },
  },
  methods: {
    confirmedList() {
      this.confirmedOrder = [];
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      const db = this.$firebase.firestore();
      this.unsubscribe = db
        .collection("orders")
        .where("workspace", "==", this.makerspaceSearchModel)
        .where("finishedAt", "==", null)
        .where("confirmed", "==", true)
        .orderBy("confirmedAt", "asc")
        .limit(50)
        .onSnapshot((v) => {
          this.confirmedFetchLoading = true;
          v.docChanges().forEach((change) => {
            const item = change.doc.data();
            if (item.createdAt) item.createdAt = item.createdAt.toDate();
            if (item.updatedAt) item.updatedAt = item.updatedAt.toDate();
            if (item.confirmedAt) item.confirmedAt = item.confirmedAt.toDate();
            if (item.finishedAt) item.finishedAt = item.finishedAt.toDate();
            if (change.type === "added") {
              this.confirmedOrder.unshift(item);
            }
            if (change.type === "modified") {
              const index = this.confirmedOrder.findIndex(
                (order) => order.id === item.id
              );
              if (index !== -1) {
                this.$set(this.confirmedOrder, index, item);
              }
            }
            if (change.type === "removed") {
              const index = this.confirmedOrder.findIndex(
                (order) => order.id === item.id
              );
              if (index !== -1) {
                this.confirmedOrder.splice(index, 1);
              }
            }
          });
          this.confirmedFetchLoading = false;
        });
    },
    getStatusData(statusLevel) {
      return this.orderStatusItem[statusLevel];
    },
    changeStatusData(item, index) {
      item.status = index;
      this.saveOrder(item);
    },
    openDialog(value) {
      this.dialogData = _.merge(
        _.cloneDeep(constants.orderFormInterface),
        value
      );
      this.orderDialog = true;
    },
    closeDialog() {
      this.dialogData = _.cloneDeep(constants.orderFormInterface);
      this.orderDialog = false;
    },
    async saveOrder(order) {
      if (order.status < 3) {
        if (order.confirmed) {
          order.confirmed = false;
          order.confirmedAt = null;
        }
      } else {
        if (!order.confirmed) {
          order.confirmed = true;
          order.confirmedAt = new Date();
        }
      }
      try {
        await Promise.all(
          order.file.map(async (v) => {
            if (v.previewImg.startsWith("data:image")) {
              const blob = await (await fetch(v.previewImg)).blob();
              const result = await firestorageUtils.uploadFile({
                file: blob,
                path: v.filePath.replace(/\.stl$/, "_Img.png"),
              });
              v.previewImg = result.downloadURL;
            }
          })
        );
        await this.$axios.put("admin/updateOrder/" + order.id, {
          admin: this.$store.getters["auth/user/GET_USER"].displayName,
          item: order,
        });
      } catch (e) {
        this.$toasted.global.error(e.message);
      }
      this.closeDialog();
    },
    async printPDF(item) {
      try {
        const response = await fetch(item.docs.orderDocDownloadUrl);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        const iframe = this.$refs.pdf;
        iframe.src = objectUrl + "#toolbar=0";
        iframe.onload = () => {
          iframe.focus();
          iframe.contentWindow.print();
          setTimeout(() => {
            const isPrintSuccessful =
              window.confirm("주문서가 정상 출력되었나요?");
            if (isPrintSuccessful) {
              item.docs.print = true;
              this.saveOrder(item);
            }
          }, 500);
        };
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
